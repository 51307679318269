import { ADD_UNIT_FAILURE,ADD_UNIT_REQUEST,ADD_UNIT_SUCCESS ,GET_BASE_UNITS,EDIT_UNIT_FAILURE,EDIT_UNIT_SUCCESS,EDIT_UNIT_REQUEST} from '../actions/types';

const addUnitStatus =  {
    isLoading:null
}

const editUnitStatus = {
    isLoading:null
}

const baseUnitList=[{

}]

export const addUnit= (state = addUnitStatus, { type, payload }) => {
    switch (type){
        case ADD_UNIT_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case ADD_UNIT_SUCCESS:
            state.isLoading = false;
            return {
                ...state
            }
            case ADD_UNIT_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}

export const editUnit= (state = editUnitStatus, { type, payload }) => {
    switch (type){
        case EDIT_UNIT_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case EDIT_UNIT_SUCCESS:
            state.isLoading = false;
            return {
                ...state
            }
            case EDIT_UNIT_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}


export const units = (state = baseUnitList, {type, payload}) => {
    
    switch (type) {
        case GET_BASE_UNITS:
            state.baseUnitList=payload;
            return {
                ...state
            }
        default:
            return state;
    }
}

