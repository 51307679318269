import { GET_ALL_CHAIN_CUSTOMERS_FAILURE, GET_ALL_CHAIN_CUSTOMERS_REQUEST, GET_ALL_CHAIN_CUSTOMERS_SUCCESS, GET_LIST_OF_PRICELIST_FAILURE, GET_LIST_OF_PRICELIST_REQUEST, GET_LIST_OF_PRICELIST_SUCCESS } from "../actions/types";

const getListOfPriceList= {
    data: [],
    isLoading: null,
 };

 const getAllChainCustomers ={
    data: [],
    isLoading: null
 }




 export const fetchPriceListData= (state = getListOfPriceList, { type, payload }) => {
    switch (type){
        case GET_LIST_OF_PRICELIST_REQUEST:
            state.isLoading = true;
            state.data=[];
            return {
                ...state
            }
            case GET_LIST_OF_PRICELIST_SUCCESS:
            state.isLoading = false;
            state.data=payload;
            return {
                ...state
            }
            case GET_LIST_OF_PRICELIST_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}

export const getAllChainCustomersData = (state = getAllChainCustomers, { type, payload }) => {
    switch (type){
        case GET_ALL_CHAIN_CUSTOMERS_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case GET_ALL_CHAIN_CUSTOMERS_SUCCESS:
            state.isLoading = false;
            state.data=payload;
            return {
                ...state
            }
            case GET_ALL_CHAIN_CUSTOMERS_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}