export default {
    "brandName" : "QueueBuster",
    "brandLogo" : require("../icons/qb_grey.svg"),
    "brandPrimaryColor":'#498bdc',
    "brandSecondaryColor":"#f6f6f7",
    "brandHeaderColor":'#1d4067',
    "loginScreen": {
        "brandLogo": require("../icons/new_qblogo.svg"),
        "headerLogo": require("../icons/new_qblogo.svg"),
        "headerColor": "#fff",
        "primaryColor": "#2A6AB4",
        "secondaryColor": "#2A6AB4",
        "logoWidth": "200px",
        "headerLogoWidth": "200px"
    },
    "sidebarIcons":{
        "home":require("../icons/home.svg"),
        "catalog":require("../icons/catalogue.svg"),
        "inventory":require("../icons/inventory.svg"),
        "purchase":require("../icons/purchase.svg"),
        "customer":require("../icons/customer.svg"),
        "reports":require("../icons/reports.svg"),
        "settings":require("../icons/organisation_setup.svg"),
        "estore":require("../icons/estore.svg"),
        "khata":require("../icons/Khata.svg"),
        "developer":require("../icons/webhook.svg"),
        "integrations":require("../icons/integrations.png"),
        "billing":require("../icons/home.svg"),
        "appointments":require("../icons/appointments.svg"),
        "urbanpiper":require("../icons/UrbanpiperMenuIcon.svg"),
        "kds":require("../icons/home.svg"),
        "expense" : require("../icons/ExpenseManagement.png"),
        "reservation":require("../icons/reservation.svg"),
        "wera":require("../icons/wera.svg"),
        "employeeIcon":require("../icons/employeeModuleIcon.svg"),
        "walletIcon":require("../icons/walletModuleIcon.svg"),

      },
      "miscIcons":{
          "downArrow":require("../icons/blue-down.svg"),
          "backArrow":require("../icons/left_icon.svg"),
          "editIcon":require("../icons/defaultedit.svg"),
          "plusIcon":require("../icons/defaultplus.svg"),
          "crossIcon":require("../icons/cross-icon.svg"),
          "addCustomer":require("../icons/AddCustomer_Icon.svg"),
          "trash":require("../icons/delete-icon.svg"),
          "sortIcon":require("../icons/table-sort-arrow.svg"),
          "gridIcon":require("../icons/display-toggle.svg"),
          "listIcon":require("../icons/List.svg"),
          "plusIcon":require("../icons/Plus_Icon_Billing.svg"),
          "minusIcon":require("../icons/Minus_Icon_Billing.svg"),
          "filterIcon":require("../icons/filter-icon.webp"),
          "success":require("../images/payment_successful.png"),
          "calendar":require("../images/ic-calendar-24-blue.png"),
      },
      suspenseLoaderUrl: "https://quebuster.s3.ap-south-1.amazonaws.com/website/assets/QB_LOADER.gif",
      filter: "invert(50%) sepia(86%) saturate(1888%) hue-rotate(192deg) brightness(94%) contrast(83%)",
      languageOptions:["en-in","en","hi","ar"]
}
