import {ERRORS} from '../actions/types';

const initialState = {

}

export default (state = initialState,action) => {
    
    switch(action.type){

        case ERRORS:

        state = action.payload;
        return state;

        default: 
            return state;
    }

}  