import { FETCH_STOREPRICE_FAILURE, FETCH_STOREPRICE_REQUEST, FETCH_STOREPRICE_SUCCESS, GET_NONSTORE_PRODS_FAILURE,
    GET_NONSTORE_PRODS_REQUEST,GET_NONSTORE_PRODS_SUCCESS } from '../actions/types';

const nonStoreProdsStatus =  {
    data:[],
    isLoading:null,
}

const productStorePriceStatus =  {
    data:[],
    isLoading:null
}



export const nonStoreProds= (state = nonStoreProdsStatus, { type, payload }) => {

    switch (type){
        case GET_NONSTORE_PRODS_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case GET_NONSTORE_PRODS_SUCCESS:
            state.isLoading = false;
            state.data=payload;
            // console.log("kdfjkdjfkpayload",payload)

            return {
                ...state
            }
            case GET_NONSTORE_PRODS_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}


export const fetchStorePrices= (state = productStorePriceStatus, { type, payload }) => {
    switch (type){
        case FETCH_STOREPRICE_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case FETCH_STOREPRICE_SUCCESS:
            state.isLoading = false;
            state.data=payload;
            return {
                ...state
            }
            case FETCH_STOREPRICE_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}



