import { MANUFACTURERS } from '../actions/types';

const initialState =  [{
            manufacturers: []
}]

export default (state = initialState, { type, payload }) => {
    switch (type){
        case MANUFACTURERS:
        
        state = payload.data && payload.data.manufacturers.data;
        
        return payload.data.manufacturers.data;
        default:
            return state;
    }
}


    