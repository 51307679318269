import {
   MODIFIERS_LIST,
   MODIFIERS_GROUP_LIST,
   MAPPED_MODIFIERS_LIST,
   MODIFIERS_PROD_LIST,
   GET_MODIFIERS_FAILURE,
   GET_MODIFIERS_SUCCESS,
   GET_MODIFIERS_REQUEST,
   GET_EDITPRODUCT_FAILURE,
   GET_EDITPRODUCT_SUCCESS,
   GET_EDITPRODUCT_REQUEST,
   CLEAR_EDITPRODUCT,
   GET_PRODUCT_MODIFIERS
} from '../actions/types';

const initialState = {
   modifiersList: [],
   modifiersGroupList: [],
   mappedModifiersList: [],
};

const getProductModifiersStatus = {
   data: [],
   isLoading: null,
};

const getProductChargesStatus = {
   data: [],
   isLoading: null,
};

const getModifiersProductWebStatus = {
   data: [],
   updateModifierData: [],
   isLoading: null,
};

// const initialState=[]

export default (state = initialState, action) => {
   switch (action.type) {
      case MODIFIERS_LIST:
         state.modifiersList = action.payload;
         return { ...state };

      case MODIFIERS_GROUP_LIST:
         state.modifiersGroupList = action.payload;
         return { ...state };

      case MAPPED_MODIFIERS_LIST:
         state.mappedModifiersList = action.payload;
         return { ...state };
      case MODIFIERS_PROD_LIST:
         state.modifiersProdList = action.payload;
         return { ...state };
      default:
         return state;
   }
};

export const getModifiersProductWeb = (state = getModifiersProductWebStatus, { type, payload }) => {
   switch (type) {
      case GET_MODIFIERS_REQUEST:
         state.isLoading = true;
         return {
            ...state,
         };
      case GET_MODIFIERS_SUCCESS:
         state.isLoading = false;
         state.updateModifierData = payload;
         return {
            ...state,
         };
      case GET_MODIFIERS_FAILURE:
         state.isLoading = false;
         return {
            ...state,
         };
      default:
         return state;
   }
};

export const getProductCharges = (state = getProductChargesStatus, { type, payload }) => {
   switch (type) {
      case GET_EDITPRODUCT_REQUEST:
         state.isLoading = true;
         return {
            ...state,
         };
      case GET_EDITPRODUCT_SUCCESS:
         state.isLoading = false;
         state.data = payload;
         return {
            ...state,
         };
      case GET_EDITPRODUCT_FAILURE:
         state.isLoading = false;
         return {
            ...state,
         };
      default:
         return state;
   }
};

export const clearProductCharges = (state = getProductChargesStatus, { type, payload }) => {
   switch (type) {
      case CLEAR_EDITPRODUCT:
         state.isLoading = false;
         state.data = payload;
         return {
            ...state,
         };
      default:
         return state;
   }
};

export const productModifiers = (state = getProductModifiersStatus, { type, payload }) => {
   switch (type) {
      case GET_PRODUCT_MODIFIERS:
         state.isLoading = false;
         state.data = payload;
         return {
            ...state,
         };
      default:
         return state;
   }
};
