import { GET_DEPARTMENT_DATA } from "../actions/types";

const initialState = null

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_DEPARTMENT_DATA:
            state = payload.status ? [payload.data] : [];
            return payload.data;
        default:
            return state;
    }
}