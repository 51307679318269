import {
  FETCH_BANNERS_REQUEST,
  FETCH_BANNERS_SUCCESS,
  FETCH_BANNERS_FAILURE,
  FETCH_ESTOREPRODS_FAILURE,
  FETCH_ESTOREPRODS_SUCCESS,
  FETCH_ESTOREPRODS_REQUEST,
  FETCH_ESTORE_RESERV_SET_FAILURE,
  FETCH_ESTORE_RESERV_SET_SUCCESS,
  FETCH_ESTORE_RESERV_SET_REQUEST,
  FETCH_ESTORE_CHAINSETTINGS_REQUEST,
  FETCH_ESTORE_CHAINSETTINGS_SUCCESS,
  FETCH_ESTORE_CHAINSETTINGS_FAILURE,
  FETCH_ESTORE_STORESETTINGS_REQUEST,
  FETCH_ESTORE_STORESETTINGS_SUCCESS,
  FETCH_ESTORE_STORESETTINGS_FAILURE,
  ESTORE_BULKCATALOG_REQUEST,
  ESTORE_BULKCATALOG_SUCCESS,
  ESTORE_BULKCATALOG_FAILURE,
  FETCH_ONLINE_CREDIT_INFO_REQUEST,
  FETCH_ONLINE_CREDIT_INFO_SUCCESS,
  FETCH_ONLINE_CREDIT_INFO_FAILURE,
  FETCH_CREDIT_PACKAGE_INFO_SUCCESS,
  FETCH_CREDIT_PACKAGE_INFO_FAILURE,
  FETCH_CREDIT_PACKAGE_INFO_REQUEST,
  FETCH_ONLINE_CREDIT_RATE,
} from "../actions/types";

const getBannersStatus = {
  data: [],
  isLoading: null,
};

const getOnlineStoreProdsStatus = {
  data: [],
  isLoading: null,
};

const getReservationSettingsStatus = {
  data: [],
  isLoading: null,
};

const getOnlineChainSettingsStatus = {
  data: [],
  isLoading: null,
};

const getOnlineStoreSettingsStatus = {
  data: [],
  isLoading: null,
};

const estoreProductStorePriceStatus = {
  data: [],
  isLoading: null,
};

const onlineCreditIfo = {
  data: [],
  isLoading: null,
};

const creditPackage = {
  data: [],
  isLoading: null,
  creditRate: {},
};

export const getOnlineStoreProds = (
  state = getOnlineStoreProdsStatus,
  { type, payload }
) => {
  switch (type) {
    case FETCH_ESTOREPRODS_REQUEST:
      state.isLoading = true;
      return {
        ...state,
      };
    case FETCH_ESTOREPRODS_SUCCESS:
      state.isLoading = false;
      state.data = payload;
      return {
        ...state,
      };
    case FETCH_ESTOREPRODS_FAILURE:
      state.isLoading = false;
      return {
        ...state,
      };
    default:
      return state;
  }
};

export const banners = (state = getBannersStatus, { type, payload }) => {
  switch (type) {
    case FETCH_BANNERS_REQUEST:
      state.isLoading = true;
      return {
        ...state,
      };
    case FETCH_BANNERS_SUCCESS:
      state.isLoading = false;
      state.data = payload;
      return {
        ...state,
      };
    case FETCH_BANNERS_FAILURE:
      state.isLoading = false;
      return {
        ...state,
      };
    default:
      return state;
  }
};

export const getReservationSettings = (
  state = getReservationSettingsStatus,
  { type, payload }
) => {
  switch (type) {
    case FETCH_ESTORE_RESERV_SET_REQUEST:
      state.isLoading = true;
      return {
        ...state,
      };
    case FETCH_ESTORE_RESERV_SET_SUCCESS:
      state.isLoading = false;
      state.data = payload;
      return {
        ...state,
      };
    case FETCH_ESTORE_RESERV_SET_FAILURE:
      state.isLoading = false;
      return {
        ...state,
      };
    default:
      return state;
  }
};

export const getOnlineChainSettings = (
  state = getOnlineChainSettingsStatus,
  { type, payload }
) => {
  switch (type) {
    case FETCH_ESTORE_CHAINSETTINGS_REQUEST:
      state.isLoading = true;
      return {
        ...state,
      };
    case FETCH_ESTORE_CHAINSETTINGS_SUCCESS:
      state.isLoading = false;
      state.data = payload;
      return {
        ...state,
      };
    case FETCH_ESTORE_CHAINSETTINGS_FAILURE:
      state.isLoading = false;
      return {
        ...state,
      };
    default:
      return state;
  }
};

export const getOnlineStoreSettings = (
  state = getOnlineStoreSettingsStatus,
  { type, payload }
) => {
  switch (type) {
    case FETCH_ESTORE_STORESETTINGS_REQUEST:
      state.isLoading = true;
      return {
        ...state,
      };
    case FETCH_ESTORE_STORESETTINGS_SUCCESS:
      state.isLoading = false;
      state.data = payload;
      return {
        ...state,
      };
    case FETCH_ESTORE_STORESETTINGS_FAILURE:
      state.isLoading = false;
      return {
        ...state,
      };
    default:
      return state;
  }
};

export const fetchStoreCatalogue = (
  state = estoreProductStorePriceStatus,
  { type, payload }
) => {
  switch (type) {
    case ESTORE_BULKCATALOG_REQUEST:
      state.isLoading = true;
      return {
        ...state,
      };
    case ESTORE_BULKCATALOG_SUCCESS:
      state.isLoading = false;
      state.data = payload;
      return {
        ...state,
      };
    case ESTORE_BULKCATALOG_FAILURE:
      state.isLoading = false;
      return {
        ...state,
      };
    default:
      return state;
  }
};

export const getOnlineOrderCreditInfo = (
  state = onlineCreditIfo,
  { type, payload }
) => {
  switch (type) {
    case FETCH_ONLINE_CREDIT_INFO_REQUEST:
      state.isLoading = true;
      return {
        ...state,
      };
    case FETCH_ONLINE_CREDIT_INFO_SUCCESS:
      state.isLoading = false;
      state.data = payload;
      return {
        ...state,
      };
    case FETCH_ONLINE_CREDIT_INFO_FAILURE:
      state.isLoading = false;
      return {
        ...state,
      };
    default:
      return state;
  }
};

export const getCreditPackageReducer = (
  state = creditPackage,
  { type, payload }
) => {
  switch (type) {
    case FETCH_CREDIT_PACKAGE_INFO_REQUEST:
      state.isLoading = true;
      return {
        ...state,
      };
    case FETCH_CREDIT_PACKAGE_INFO_SUCCESS:
      state.isLoading = false;
      state.data = payload;
      return {
        ...state,
      };
    case FETCH_CREDIT_PACKAGE_INFO_FAILURE:
      state.isLoading = false;
      return {
        ...state,
      };
    case FETCH_ONLINE_CREDIT_RATE:
      state.creditRate = payload;
      return {
        ...state,
      };
    default:
      return state;
  }
};



