import {GET_FLOORS_TABLE} from '../actions/types'

const tableData=[]

export const getTableLayoutWeb = (state = tableData, {type, payload}) => {
    // console.log(payload,'TABLE__DATA');
    
    switch (type) {
        case GET_FLOORS_TABLE:
            state.tableData=payload;
            return {
                ...state
            }
        default:
            return state;
    }
}