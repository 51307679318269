import {
    FETCH_PI_LIST_SUCCESS,
    FETCH_CUSTOMERS_LIST_SUCCESS,
    FETCH_DEVICES_LIST_SUCCESS,
    FETCH_USERS_LIST_SUCCESS,
    FETCH_PI_DETAIL_SUCCESS,
    FETCH_PRODUCTS_IN_PI_SUCCESS,
    FETCH_BRANDS_FOR_PRODUCTS_SUCCESS,
    FETCH_CATEGORIES_FOR_PRODUCTS_SUCCESS,
    LOGOUT,
    FETCH_REPORT_TRACKER_LIST_SUCCESS,
} from '../actions/types';

export default {
    PIList : function(state = null, action) {
        switch (action.type) {
            case FETCH_PI_LIST_SUCCESS:
                return action.payload.data;
            default:
                return state;
        }
    },

    customersList : function(state = null, action) {
        switch (action.type) {
            case FETCH_CUSTOMERS_LIST_SUCCESS:
                return action.payload.data;
            default:
                return state;
        }
    },

    messageTypes : function(state = null, action) {
        switch(action.type) {
            case FETCH_CUSTOMERS_LIST_SUCCESS:
                return action.payload.messageType;
            default:
                return state;
        }  
    },

    devicesList : function(state = null, action) {
        switch (action.type) {
            case FETCH_DEVICES_LIST_SUCCESS:
                return action.payload.data;
            default:
                return state;
        }
    },

    usersList : function(state = null, action) {
        switch (action.type) {
            case FETCH_USERS_LIST_SUCCESS:
                return action.payload.data;
            default:
                return state;
        }
    },

    PIDetail : function(state = null, action) {
        switch (action.type) {
            case FETCH_PI_DETAIL_SUCCESS:
                return action.payload;
            default:
                return state;
        }
    },

    productsInPI : function(state = null, action) {
        switch (action.type) {
            case FETCH_PRODUCTS_IN_PI_SUCCESS:
                return action.payload.data;
            default:
                return state;
        }
    },

    brandsForProducts : function(state = null, action) {
        switch (action.type) {
            case FETCH_BRANDS_FOR_PRODUCTS_SUCCESS:
                return action.payload.data;
            case LOGOUT:
                return null;
            default:
                return state;
        }
    },

    categoriesForProducts : function(state = null, action) {
        switch (action.type) {
            case FETCH_CATEGORIES_FOR_PRODUCTS_SUCCESS:
                return action.payload.data;
            case LOGOUT:
                return null;
            default:
                return state;
        }
    },
    reportTrackerList : function(state = [], action) {
        switch (action.type) {
            case FETCH_REPORT_TRACKER_LIST_SUCCESS:
                return action.payload;
            default:
                return state;
        }
    },
}