import {
//    FETCH_EXPENSE_ORDERS_REQUEST,
   FETCH_EXPENSE_ORDERS_SUCCESS,
//    FETCH_EXPENSE_ORDERS_FAILURE,

//    FETCH_EXPENSE_ORDERS_DETAILS_REQUEST,
   FETCH_EXPENSE_ORDERS_DETAILS_SUCCESS,
//    FETCH_EXPENSE_ORDERS_DETAILS_FAILURE,

//    FETCH_PRODUCT_EXPENSE_ORDER_INFO_REQUEST,
   FETCH_PRODUCT_EXPENSE_ORDER_INFO_SUCCESS,
//    FETCH_PRODUCT_EXPENSE_ORDER_INFO_FAILURE,

//    FETCH_EXPENSE_CATEGORY_REQUEST,
   FETCH_EXPENSE_CATEGORY_SUCCESS,
//    FETCH_EXPENSE_CATEGORY_FAILURE,

//    FETCH_ALTER_EXPENSE_CATEGORY_REQUEST,
   FETCH_ALTER_EXPENSE_CATEGORY_SUCCESS,
//    FETCH_ALTER_EXPENSE_CATEGORY_FAILURE,

} from '../actions/types';

export const expenseOrdersList = (state = null, action) => {
    switch(action.type) {
        case FETCH_EXPENSE_ORDERS_SUCCESS:
            return action.payload.data.data;
        default:
            return state;
    }
}

export const expenseOrderDetail = (state = null, action) => {
    switch(action.type) {
        case FETCH_EXPENSE_ORDERS_DETAILS_SUCCESS:
            return action.payload.data.data;
        default:
            return state;
    }
}

export const productExpenseOrderInfo = (state = null, action) => {
    switch(action.type) {
        case FETCH_PRODUCT_EXPENSE_ORDER_INFO_SUCCESS:
            return action.payload.data.data;
        default:
            return state;
    }
}


export const fetchExpenseCatergory = (state = null, action) => {
    switch(action.type) {
        case FETCH_EXPENSE_CATEGORY_SUCCESS:
            return action.payload.data.data;
        default:
            return state;
    }
}

export const alterExpenseCatergory = (state = null, action) => {
    switch(action.type) {
        case FETCH_ALTER_EXPENSE_CATEGORY_SUCCESS:
            return action.payload.data.data;
        default:
            return state;
    }
}