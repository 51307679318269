import { GET_MEMBERSHIPS_FAILURE,GET_MEMBERSHIPS_REQUEST,GET_MEMBERSHIPS_SUCCESS,GET_SUBCATEGORY, LOGOUT } from '../actions/types';

const getMembershipsStatus =  {
    data:[],
    isLoading:null
}

const subCategoryList=[{

}] 

export const membershipsWeb= (state = getMembershipsStatus, { type, payload }) => {
    switch (type){
        case GET_MEMBERSHIPS_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case GET_MEMBERSHIPS_SUCCESS:
            state.isLoading = false;
            state.data=payload;
            return {
                ...state
            }
            case GET_MEMBERSHIPS_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}


export const getSubCategories = (state = subCategoryList, {type, payload}) => {
    
    switch (type) {
        case GET_SUBCATEGORY:
                // console.log(payload,'badshan');
            state.subCategoryList=payload;
            return {
                ...state
            }
        case LOGOUT:
            return [{}];
        default:
            return state;
    }
}