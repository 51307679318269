import { COMBOS } from '../actions/types';

const initialState =  null;

export default (state = initialState, { type, payload }) => {
    switch (type){
        case COMBOS:
        state = payload.data && payload.data.comboGroupsWeb.data;
        return payload.data  && payload.data.comboGroupsWeb.data;
        default:
            return state;
    }
}