import { FETCH_BUNDLE_LIST_FAILURE, FETCH_BUNDLE_LIST_REQUEST, FETCH_BUNDLE_LIST_SUCCESS } from "../actions/types";


const bundleData = {
    data:[],
    isLoading: false
}

export const listOfBundles= (state = bundleData, { type, payload }) => {
    
    switch (type){
        case FETCH_BUNDLE_LIST_REQUEST:
            state.isLoading = true;
            return {
                ...state,data:[]
            }
            case FETCH_BUNDLE_LIST_SUCCESS:
            state.isLoading = false;
            // state.data=payload
            return {
                ...state,data: payload
            }
            case FETCH_BUNDLE_LIST_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}