import { NEW_CHART_DATA, CHECK_STORE_ACTIVATION_ON_DEVICE_REQUEST, CHECK_STORE_ACTIVATION_ON_DEVICE_SUCCESS, CHECK_STORE_ACTIVATION_ON_DEVICE_FAILURE } from '../actions/types';
import moment from "moment";
import { requestDetail } from '../actions/inventoryAction';

const initialState =  {
        fromDate:moment().format("YYYY-MM-DD"),
        toDate:moment().format("YYYY-MM-DD"), 
        grossSales:0,
        netSales:0,
        taxes:0,
        discountTotalValue:0,
        orderCount:0,
        payment:[],
        topStoreSales:[],
        topItemSales:[],
        topCatSales:[],
        khata:{
            debited:0,
            credited:0,
            balance:0,
            khataCustomers:0,
            enabled:true
        },
        estore:{
            orders:0,
            sales:0,
            estoreCustomers:0,
            enabled:true
        },
        chart:{
            salesData:{
                label:"Revenue",
                data:[
                    {
                        orderDate:"2021-05-12",
                        grossSales:0
                    }
                ]
            }
        }
    }

const storeActivation = {
    data:[],
    isLoading:null
}

    
export const newDashboardReducer = (state = initialState, { type, payload,req }) => {
    // console.log("kskjsjks", req)
    switch (type){
        case NEW_CHART_DATA:
        state = payload;
        state.fromDate = req.fromDate;
        state.toDate = req.toDate;
        return payload;
        default:
            return state;
    }
}

export const checkStoreActivationOnDevice= (state = storeActivation, { type, payload }) => {
    switch (type){
        case CHECK_STORE_ACTIVATION_ON_DEVICE_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case CHECK_STORE_ACTIVATION_ON_DEVICE_SUCCESS:
            state.isLoading = false;
            state.data=payload;
            return {
                ...state
            }
            case CHECK_STORE_ACTIVATION_ON_DEVICE_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}
