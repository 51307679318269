import { CHART_DATA } from '../actions/types';
import moment from "moment";
const initialState =  {
      fromDate:moment().format("YYYY-MM-DD"),
      toDate:moment().format("YYYY-MM-DD"), 
      grossSales: 0,
      netSales: 0,
      orderCount: 0,
      payment: [],
      profitTrend:[],
      topStoreSales: [],
      topItemSales: [],
      topCatSales: [],
      topBrandSales:[],
      newCustomerCount: 0,
      totalCustomerCount: 0,
      productCount: 0,
      uniqueProductCount: "0%",
      chart: {
        salesData: {
          label: "Revenue",
          data: [
            {
              orderDate: "2018-08-09",
              grossSales: 0
            }
          ]
        }
      }
    }

export default (state = initialState, { type, payload,req }) => {
    switch (type){
        case CHART_DATA:
        state = payload;
        state.fromDate = req.fromDate;
        state.toDate = req.toDate;
        return payload;
        default:
            return state;
    }
}