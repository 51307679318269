import { CATEGORIES } from '../actions/types';

const initialState = null;


export default (state = initialState, { type, payload }) => {
    switch (type){
        case CATEGORIES:
            return payload;
        default:
            return state;
    }
}