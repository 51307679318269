import {CHARGES_LIST} from '../actions/types';

const initialState = [];

export default (state=initialState,action)=>{

   
    switch(action.type){

        case CHARGES_LIST:
        
        state = action.payload;
        return state;
    }
    return state;
}