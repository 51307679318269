import { VOUCHERS,
    GET_GIFTVOUCHER_REQUEST,
    GET_GIFTVOUCHER_SUCCESS,
    GET_GIFTVOUCHER_FAILURE } from '../actions/types';

const initialState =  []

const fetchGiftVoucherStatus= {
    data:[],
    isLoading:null
}

export default (state = initialState, { type, payload }) => {
    switch (type){
        case VOUCHERS:
        state = payload.data &&  payload.data.getVouchersWeb.data;
        return payload.data.getVouchersWeb.data;
        default:
            return state;
    }
}

export const fetchGiftVoucher= (state = fetchGiftVoucherStatus, { type, payload }) => {
    switch (type){
        case GET_GIFTVOUCHER_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case GET_GIFTVOUCHER_SUCCESS:
            state.isLoading = false;
            state.data=payload;
            return {
                ...state
            }
            case GET_GIFTVOUCHER_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}