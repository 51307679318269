
import axios from "axios";
import { useEffect, useRef } from "react";
import { useHistory,useLocation } from "react-router-dom";

const instance = axios.create({
   baseURL: process.env.REACT_APP_NODEURL+'/api',
});



// const source = axios.CancelToken.source();

// instance.interceptors.request.use((config) => {
//   // Attach the cancel token to the config
//   config.cancelToken = source.token;
//   console.log('Interceptor called','here1');
//   return config;
// }, (error) => {
//   return Promise.reject(error);
// });


// window.addEventListener('popstate', function(event) {
//    console.log('url changed','here2');
//    source.cancel('Operation canceled due to URL change.');
//  });

// let currentUrl = window.location.href;
// setInterval(() => {
//    if (window.location.href !== currentUrl) {
//      currentUrl = window.location.href;
//      console.log("req cancelled", "here-3")
//      source.cancel('Operation canceled due to URL change.');
//    }
//  }, 100);


instance.defaults.headers.common["Authorization"] = localStorage.getItem("token");
instance.defaults.headers.common["Content-Type"] = "application/json";
if (localStorage.getItem("languagePreference")) {
   instance.defaults.headers.common["languagePreference"] = localStorage.getItem("languagePreference");
}

const disableAllButtons = () => {
   const buttons = document.getElementsByTagName("button");
   for (let i = 0; i < buttons.length; i++) {
      buttons[i].style.pointerEvents = "none";
   }
};

const enableAllButtons = () => {
   const buttons = document.getElementsByTagName("button");
   for (let i = 0; i < buttons.length; i++) {
      buttons[i].style.pointerEvents = "all";
   }
};

const AxiosInterceptor = ({ children }) => {
   const location = useLocation();
   const history = useHistory();
   const sourceRef = useRef(null);



   useEffect(() => {
      console.log(location, "history")
      let requestCount = 0;
      const requestInterceptor = (config) => {
         requestCount++;
         disableAllButtons();
         config.cancelToken = sourceRef.current.token;
         return config;
      };

      const resInterceptor = (response) => {
         requestCount--;
         if (requestCount === 0) {
            enableAllButtons();
         }
         return response;
      };

      const errInterceptor = (error) => {
         requestCount--;
         if (requestCount === 0) {
            enableAllButtons();
         }
         if (
            error?.response?.data?.tokenExpired !== undefined &&
            error.response.data.tokenExpired === true
         ) {
            window.location.assign("/");
         }

         return Promise.reject(error);
      };

      const requestInterceptorId = instance.interceptors.request.use(requestInterceptor);
      const interceptor = instance.interceptors.response.use(resInterceptor, errInterceptor);

        // Create a new CancelToken for the current URL
    sourceRef.current = axios.CancelToken.source();

    const unlisten = history.listen((location, action) => {
      if (sourceRef.current) {
        // Cancel requests for the previous URL
        sourceRef.current.cancel('Operation canceled due to URL change.');
      }

      // Create a new CancelToken for the new URL
      sourceRef.current = axios.CancelToken.source();
    });
      return () => {
         instance.interceptors.request.eject(requestInterceptorId);
         instance.interceptors.response.eject(interceptor);
        if (sourceRef.current) {
         // Cancel requests when the component is unmounted
         sourceRef.current.cancel('Operation canceled by the user.');
       }
         // Stop listening to history changes
      unlisten();
      };
   }, [history]);

   return children;
};

const billingAxios = axios.create({
   baseURL: "/api",
});

billingAxios.interceptors.request.use((config) => {
   const deviceID = localStorage.getItem('deviceID');
   const storeID = localStorage.getItem('billingStore');

   config.headers['deviceid'] = +deviceID;
   config.headers['storeid'] = +storeID;
   
   return config;
 }, (error) => {
   return Promise.reject(error);
 });

export default instance;
export { AxiosInterceptor, billingAxios };


// Authorization: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJzdGFyYnVja3MiLCJpc3MiOiJodHRwczpcL1wvYmV0YS5xdWV1ZWJ1c3Rlci5jbyIsImF1ZCI6Imh0dHBzOlwvXC9iZXRhLnF1ZXVlYnVzdGVyLmNvIiwiaWF0IjoxNzE0NDU0NDAzLCJleHAiOjE3MTcwNDY0MDMsImV4dHJhcGFyYW0iOiI2MDFmMjZmODMzZmYwYTRiY2NhZGNjYjJjYmY2NzU0NzkwM2Y2ZjJiIn0.Cb1SYDF6GlnOHhurkYa464_pSRTWU18cky7vcwA1jrA",