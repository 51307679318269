import { INIT_LOADER,HALT_LOADER
     } from '../actions/types';
    
    const loaderStatus = {isLoading:null,count:0,progressBarCount:10}

    export const initLoader= (state = loaderStatus, { type, payload }) => {
         
        switch (type){
            case INIT_LOADER:
                state.isLoading = true;
                state.count = state.count + 1;
                if(state.progressBarCount>10){
                state.progressBarCount = state.progressBarCount - 10;
                }
                console.log(state.count,'initLoaderStats')
                return {
                    ...state
                }
                case HALT_LOADER:
                    state.count = state.count - 1;
                    console.log(state.count,'haltLoaderStats')
                    if(state.count<=0){
                        state.isLoading = false;
                        state.progressBarCount = 10;
                        state.count = 0;
                    }
                    if(state.progressBarCount<90){
                    state.progressBarCount = state.progressBarCount + 10;
                    }
                return {
                    ...state
                }
            default:
                return state;
        }
    }