import {
    FETCH_ATTENDANCE_REPORT_SUCCESS,
    FETCH_INFO_DAYS_REPORT_SUCCESS,
    FETCH_CASHIER_SUMMARY_REPORT_SUCCESS,
    FETCH_MPH_SUCCESS,
    FETCH_MEMBERSHIP_TRACKER_SUCCESS,
    FETCH_MEMBERSHIP_PURCHASE_DETAIL_SUCCESS
} from '../actions/types';

let reducers = [
    {name: 'attendanceReport', actionType: FETCH_ATTENDANCE_REPORT_SUCCESS},
    {name: 'infoDaysReport', actionType: FETCH_INFO_DAYS_REPORT_SUCCESS},
    {name: 'cashierSummaryReport', actionType: FETCH_CASHIER_SUMMARY_REPORT_SUCCESS},
    {name: 'MPH', actionType: FETCH_MPH_SUCCESS},
    {name: 'membershipTracker', actionType: FETCH_MEMBERSHIP_TRACKER_SUCCESS},
    {name: 'membershipPurchaseDetailReducer', actionType: FETCH_MEMBERSHIP_PURCHASE_DETAIL_SUCCESS},
]

let commonReducer = (i) => function(state = null, action) {
    switch(action.type) {
        case reducers[i].actionType:
            return action.payload;
        default:
            return state;
    }
}

let usersReducer = {};

usersReducer['attendanceReport'] = commonReducer(0);
usersReducer['infoDaysReport'] = commonReducer(1);
usersReducer['cashierSummaryReport'] = commonReducer(2);
usersReducer['MPH'] = commonReducer(3);
usersReducer['membershipTracker'] = commonReducer(4);
usersReducer['membershipPurchaseDetailData']= commonReducer(5)

export default usersReducer;