import {GET_INCOMEHEADS} from '../actions/types'

const incomeHeadsList=[{

}]

export const incomeHeads = (state = incomeHeadsList, {type, payload}) => {
    // console.log(payload,'TABLE__DATA');
    
    switch (type) {
        case GET_INCOMEHEADS:
            state.incomeHeadsList=payload;
            return {
                ...state
            }
        default:
            return state;
    }
}