import React from "react";
import {GET_SERVICES,GET_TREATMENT_TYPES,GET_SERVICE_GROUPS,GET_CHAIN_TAXES, GET_CHAIN_CHARGES, SET_EDIT_SERVICE, GET_SERVICE_GROUPS_CATEGORIES, GET_SERVICE_SLOTS} from '../actions/types'
import { FormattedMessage } from "react-intl";
import messages from "../Internationalisation/MessageDescriptor";

const servicesList=[{

}]

const treatmentTypesList=[{

}]

const serviceGroupsList=[{

}]

const serviceGroupCategoryList=[{

}]

const taxesChainList=[{

}]

const chargesChainList=[{

}]

const serviceSlots={
 data:[]
}

export const Services = (state = servicesList, {type, payload}) => {
    // console.log(payload,'SER---vices');
    
    switch (type) {
        case GET_SERVICES:
            state.servicesList=payload;
            return {
                ...state
            }
        default:
            return state;
    }
}

export const editService = (state = servicesList, {type, payload}) => {
    switch(type) {
        case SET_EDIT_SERVICE:
            return payload;
        default:
            return state;
    }
}

export const treatmentTypes = (state = treatmentTypesList, {type, payload}) => {
    // console.log(payload,'SER---vices');
    
    switch (type) {
        case GET_TREATMENT_TYPES:
            state.treatmentTypesList=payload;
            return {
                ...state
            }
        default:
            return state;
    }
}

export const categoriesForProd = (state = serviceGroupsList, {type, payload}) => {
    // console.log(payload,'SER---vices');
    
    switch (type) {
        case GET_SERVICE_GROUPS:
            payload&&payload.unshift({ value: -1, label: "Default category (none)",categoryID: -1,categoryName: "Default category (none)" })
            state.serviceGroupsList=payload;
            console.log(payload,"Aman Payload")
            return {
                ...state
            }
        default:
            return state;
    }
}
export const serviceGroupCategories = (state = serviceGroupCategoryList, {type, payload}) => {
    
    switch (type) {
        case GET_SERVICE_GROUPS_CATEGORIES:
            payload.filter(el=>{
                if(el.categoryID == -1){
                    el.categoryName = <FormattedMessage {...messages.newKeys.apiResponse.defaultServiceGroup} children={a=>a} />;
                }
            })
            state.serviceGroupCategoryList=payload;
            return {
                ...state
            }
        default:
            return state;
    }
}

export const serviceSlotsData = (state = serviceSlots, {type, payload}) => {
    
    switch (type) {
        case GET_SERVICE_SLOTS:
            state.data=payload;
            return {
                ...state
            }
        default:
            return state;
    }
}

export const getChainTaxes = (state = taxesChainList, {type, payload}) => {
    // console.log(payload,'SER---vices');
    
    switch (type) {
        case GET_CHAIN_TAXES:
            state.taxesChainList=payload;
            return {
                ...state
            }
        default:
            return state;
    }
}

export const getChainCharges = (state = chargesChainList, {type, payload}) => {
    // console.log(payload,'SER---vices');
    
    switch (type) {
        case GET_CHAIN_CHARGES:
            state.chargesChainList=payload;
            return {
                ...state
            }
        default:
            return state;
    }
}

export const BulkCreateServiceGroup = () =>{

}

export const BulkEditServiceGroup = () =>{
    
}