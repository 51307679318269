import {FETCH_STOCK_LEVEL_SUCCESS, FETCH_STORE_STOCK_LEVEL_SUCCESS, FETCH_GROUP_STOCK_LEVEL_SUCCESS, FETCH_STOCK_TRX_HISTORY_SUCCESS, FETCH_STOCK_REQ_HISTORY_SUCCESS, FETCH_STOCK_MOVEMENT_SUCCESS, FETCH_STOCK_MOVEMENT_DETAIL_SUCCESS, FETCH_REMARKS_SUCCESS, FETCH_PROFIT_MARGIN_SUCCESS, FETCH_VENDOR_TAX_INPUT_SUCCESS, FETCH_PRODUCTS_IN_PO_SUCCESS,FETCH_STOCK_REQUEST_ANALYSIS_SUCCESS, FETCH_UNFULLFILLED_STOCK_REQUESTS_SUCCESS, FETCH_PRODUCT_AGEING_REPORT_SUCCESS,
    FETCH_PRODUCTS_DETAILS_IN_PO_SUCCESS,
    FETCH_PRODUCTS_DETAILS_IN_PO_REQUEST,
    FETCH_PRODUCTS_DETAILS_IN_PO_FAILURE,
    FETCH_LOW_STOCK_PRODUCTS_REQUEST,
    FETCH_LOW_STOCK_PRODUCTS_SUCCESS,
    FETCH_LOW_STOCK_PRODUCTS_FAILURE,
    } 
    from '../actions/types';

    const getproductsInPODetails =  {
        data:[],
        orderPayment:[],
        orderDetail:[],
        orderSummary:[],
        isLoading:null
    }
    
    const getLowStockProducts = {
        data:[],
        isLoading:null
    }

export default {
    stockLevel: function(state = null, action) {
        switch(action.type) {
            case FETCH_STOCK_LEVEL_SUCCESS:
                return action.payload;
            default:
                return state;
        }
    },

    storeStockLevel: function(state = null, action) {
        switch(action.type) {
            case FETCH_STORE_STOCK_LEVEL_SUCCESS:
                return action.payload;
            default:
                return state;
        }
    },

    groupStockLevel: function(state = null, action) {
        switch(action.type) {
            case FETCH_GROUP_STOCK_LEVEL_SUCCESS:
                return action.payload;
            default:
                return state;
        }
    },

    stockTrxHistory: function(state = null, action) {
        switch(action.type) {
            case FETCH_STOCK_TRX_HISTORY_SUCCESS:
                return action.payload;
            default:
                return state;
        }
    },

    stockReqHistory2: function(state = null, action) {
        switch(action.type) {
            case FETCH_STOCK_REQ_HISTORY_SUCCESS:
                return action.payload;
            default:
                return state;
        }
    },

    stockMovement: function(state = null, action) {
        switch(action.type) {
            case FETCH_STOCK_MOVEMENT_SUCCESS:
                return action.payload;
            default:
                return state;
        }
    },

    stockMovementDetail: function(state = null, action) {
        switch(action.type) {
            case FETCH_STOCK_MOVEMENT_DETAIL_SUCCESS:
                return action.payload;
            default:
                return state;
        }
    },
    stockRequestAnalysis: function(state = null, action) {
        switch(action.type) {
            case FETCH_STOCK_REQUEST_ANALYSIS_SUCCESS:
                return action.payload;
            default:
                return state;
        }
    },

    unfullfilledStockRequest: function(state = null, action) {
        switch(action.type) {
            case FETCH_UNFULLFILLED_STOCK_REQUESTS_SUCCESS:
                return action.payload;
            default:
                return state;
        }
    },
    productAgeingReport: function(state = null, action) {
        switch(action.type) {
            case FETCH_PRODUCT_AGEING_REPORT_SUCCESS:
                return action.payload;
            default:
                return state;
        }
    },


    remarks: function(state = null, action) {
        switch(action.type) {
            case FETCH_REMARKS_SUCCESS:
                return action.payload.data;
            default:
                return state;
        }
    },

    profitMargin: function(state = null, action) {
        switch(action.type) {
            case FETCH_PROFIT_MARGIN_SUCCESS:
                return action.payload;
            default:
                return state;
        }
    },

    vendorTaxInput: function(state = null, action) {
        switch(action.type) {
            case FETCH_VENDOR_TAX_INPUT_SUCCESS:
                return action.payload;
            default:
                return state;
        }
    },

    productsInPO: function(state = null, action) {
        switch(action.type) {
            case FETCH_PRODUCTS_IN_PO_SUCCESS:
                return action.payload;
            default:
                return state;
        }
    },
}



export const productsInPODetails= (state = getproductsInPODetails, { type, payload }) => {
    switch (type){
        case FETCH_PRODUCTS_DETAILS_IN_PO_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case FETCH_PRODUCTS_DETAILS_IN_PO_SUCCESS:
            state.isLoading = false;
            state.data=payload;
            state.orderPayment=payload.orderPayment
            state.orderDetail=payload.orderDetail
            state.orderSummary=payload.orderSummary
            return {
                ...state
            }
            case FETCH_PRODUCTS_DETAILS_IN_PO_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}

export const LowStockProducts= (state = getLowStockProducts, { type, payload }) => {
    switch (type){
        case FETCH_LOW_STOCK_PRODUCTS_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case FETCH_LOW_STOCK_PRODUCTS_SUCCESS:
            state.isLoading = false;
            state.data=payload;
            
            return {
                ...state
            }
            case FETCH_LOW_STOCK_PRODUCTS_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}
