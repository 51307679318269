import { TAX_GROUP_LIST } from "../actions/types";

export const taxGroups = (state = [], {type, payload}) => {
    switch (type) {
        case TAX_GROUP_LIST:
            state=payload.data;
            debugger
            return state;
        default:
            return state;
    }
}