import {SET_LANGUAGE} from '../actions/types'

const languageStatus = {isLanguage:undefined,locale:undefined}

export const setLanguage = (state = languageStatus, {type, payload}) => {
    switch (type) {
        case SET_LANGUAGE:
            state.isLanguage=payload.value;
            state.locale=payload.locale;
            return {
                ...state
            }
        default:
            return state;
    }
}