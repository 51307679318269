import { BRANDS } from '../actions/types';

const initialState =  [{
            brandID:"",
            brandName:"",
            prodCount:0,
}]

export default (state = initialState, { type, payload }) => {
    switch (type){
        case BRANDS:
        
        state = payload?.data && payload?.data?.brands?.data;
        
        return payload.data.brands.data;
        default:
            return state;
    }
}


// export const brandsForProd = (state = brandsListData, {type, payload}) => {
    
//     switch (type) {
//         case BRANDS_FORPROD:
//                 console.log(payload,'');
//             state.brandsListData=payload;
//             return {
//                 ...state
//             }
//         default:
//             return state;
//     }
// }