import {TOGGLE_MODULE_SIDEBAR, HIDE_MODULE_SIDEBAR, CHANGE_GLOBAL_PATHNAME, SHOW_GLOBAL_DATERANGEMODAL, HIDE_GLOBAL_DATERANGEMODAL, SHOW_POPOVER, HIDE_POPOVER, OPENSIDEBAR_FOR_HOME, HIDE_SUBMODULE_SIDEBAR, SHOW_SUBMODULE_SIDEBAR, TOGGLE_SUBMODULE_SIDEBAR} from '../actions/types';


export default {

    showModuleSidebar: function(state = false, {type}) {
        switch(type) {
            case TOGGLE_MODULE_SIDEBAR:
                return !state;
            case HIDE_MODULE_SIDEBAR:
                return false;
            case OPENSIDEBAR_FOR_HOME : 
                return true;
            default:
                return state;
        }
    },
    
    toggleSubModuleSidebar: function(state = true, { type }) {
        switch(type) {
            case HIDE_SUBMODULE_SIDEBAR:
                return false;
            case SHOW_SUBMODULE_SIDEBAR : 
                return true;
            case TOGGLE_SUBMODULE_SIDEBAR : 
                return !state;
            default:
                return state;
        }
    },

    globalPathname: function(state = window.location.pathname, {type, payload}) {
        switch(type) {
            case CHANGE_GLOBAL_PATHNAME:
                return payload;
            default:
                return state;
        }
    },

    globalDateRangeModal: function(state = null, {type, payload}) {
        switch(type) {
            case SHOW_GLOBAL_DATERANGEMODAL:
                return payload;
            case HIDE_GLOBAL_DATERANGEMODAL:
                return null;
            default: 
                return state;
        }
    },

    popoverState: function(state = {show: false, body: null}, {type, payload}) {
        switch(type) {
            case SHOW_POPOVER:
            case HIDE_POPOVER:
                return payload;
            default:
                return state;
        }
    },

}