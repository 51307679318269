import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk'

import rootReducer from './reducers'
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; 


const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['inventoryData','stockProductsListChainStatus','languageStatus','billingCustomers','billingCart','billingHoldOrders','billingAppSettings',"historyState","taxesChainList","chargesChainList","getProductChargesStatus"]
};

const persistedReducer = persistReducer(persistConfig, rootReducer)

const initialState = { 
}

const middleware = [
    thunk,
   // logger
]

const composedEnhancers = compose(
    applyMiddleware(...middleware),
   //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    );
   
    export default () => {
        const store = createStore(persistedReducer, initialState, composedEnhancers);
        return { store, persistor: persistStore(store) };
      };
