import {
    CATEGORIES_FOR_PROD,SUB_CATEGORIES_FOR_PROD, COLORS,
    BRANDS_FOR_PROD, SIZES, CHARGES, BATCHES, FETCH_PRODUCTS_SUCCESS, VENDORS, UNITS,
    FETCH_CATEGORIES_FOR_PRODUCTS_SUCCESS, GET_SUBCATEGORY,
    FETCH_COLORS_SUCCESS, FETCH_BRANDS_FOR_PRODUCTS_SUCCESS,
    FETCH_SIZE_SUCCESS, FETCH_VENDORS_SUCCESS, FETCH_CHARGES_SUCCESS,
    FETCH_BATCHES_SUCCESS, FETCH_UNITS_SUCCESS, CATEGORIES, LOGOUT, GET_ALL_HSNCODE_REQUEST, GET_ALL_HSNCODE_SUCCESS, GET_ALL_HSNCODE_FAILURE
} from '../actions/types';

const initialState = {
   categoryList: [],
   subCategoryList:[],
   colorList:[],
   sizeList:[],
   brandList: [],
   chargeList: [],
   taxList: [],
   productList: [],
   batchList: [],
   unitsList: [],
   vendorsList: [],
}

const hsnInitialState= {
    isLoading:null,
    data:[]
}

export default (state = initialState, { type, payload }) => {
    switch (type){
        case CATEGORIES:
        state.categoryList = payload.data ;
        return {
            ...state
        }
        case GET_SUBCATEGORY:
            state.subCategoryList = payload.data ;
            return {
                ...state
            }
        case FETCH_COLORS_SUCCESS:
        state.colorList = payload.data;
        return {
            ...state
        }
        case FETCH_BRANDS_FOR_PRODUCTS_SUCCESS:
        state.brandList = payload.data;
        return {
            ...state
        }
        case FETCH_SIZE_SUCCESS:
        state.sizeList = payload.data;
        return {
            ...state
        }
        case FETCH_CHARGES_SUCCESS:
        state.chargeList = payload.chargeList;
        state.taxList = payload.taxList;
        return {
            ...state
        }
        case FETCH_BATCHES_SUCCESS:
        state.batchList = payload.batchList;
        return {
            ...state
        }
        case FETCH_PRODUCTS_SUCCESS:
        state.productList = payload.data.list;
        return {
            ...state
        }
        case FETCH_VENDORS_SUCCESS:
        state.vendorsList = payload.data;
        return {
            ...state
        }
        case FETCH_UNITS_SUCCESS:
        state.unitsList = payload.data;
        return {
            ...state
        }
        case LOGOUT:
            return {
                categoryList: [],
                subCategoryList:[],
                colorList:[],
                sizeList:[],
                brandList: [],
                chargeList: [],
                taxList: [],
                productList: [],
                batchList: [],
                unitsList: [],
                vendorsList: [],
            };
        default:
            return state;
    }
}




export const getHsnCode = (state = hsnInitialState, { type, payload }) => {
    switch (type) {
       case GET_ALL_HSNCODE_REQUEST:
          state.isLoading = true;
          return {
             ...state,
          };
       case GET_ALL_HSNCODE_SUCCESS:
          state.isLoading = false;
          let hsnData= payload&&payload?.map((el)=>{
            el.label=el.HSNCode+"";
            el.value=el.HSNCode+"";
            return el;
          })
          state.data = hsnData;
          return {
             ...state,
          };
       case GET_ALL_HSNCODE_FAILURE:
          state.isLoading = false;
          return {
             ...state,
          };
       default:
          return state;
    }
 };