import { STORE_ROOM_ID } from '../actions/types';

const initialState = {
   roomID: null
}

export default (state = initialState, { type, payload }) => {
    switch (type){
        case STORE_ROOM_ID:
        state.roomID = payload;
        return {
            ...state
        }
        default:
            return state;
    }
}