import {CHECK_USERNAME_REQUEST, CHECK_USERNAME_SUCCESS, CHECK_USERNAME_FAILURE, CHECK_OTP_REQUEST, CHECK_OTP_SUCCESS, CHECK_OTP_FAILURE, CHECK_PASSWORD_REQUEST, CHECK_PASSWORD_SUCCESS, CHECK_PASSWORD_FAILURE, CHECK_SIGNUP_REQUEST, CHECK_SIGNUP_SUCCESS, CHECK_SIGNUP_FAILURE, INIT_LOADER, HALT_LOADER, ERROR_NOTIFIER} from '../actions/types';


const getUsernameDetail =  {
    data:[],
    isLoading:null
}

const getOtpDetail =  {
    data:[],
    isLoading:null
}

const passwordUpdated = {
    data : [],
    isLoading : null
}

const signUpUpdated = {
    data : [],
    isLoading : null
}

export const checkEmail = (state = getUsernameDetail, { type, payload }) => {
    // console.log("inside reducers",type, payload)
    switch (type){
        case CHECK_USERNAME_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case CHECK_USERNAME_SUCCESS:
            state.isLoading = false;
            state.data=payload;
            return {
                ...state
            }
            case CHECK_USERNAME_FAILURE:
                state.isLoading = false;
                state.data=payload;
                return {
                    ...state
                }
        default:
            return state;
    }
}


export const checkOtpWeb = (state = getOtpDetail, { type, payload }) => {
    // console.log("inside reducers otp",type, payload)
    switch (type){
        case CHECK_OTP_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case CHECK_OTP_SUCCESS:
            state.isLoading = false;
            state.data=payload;
            return {
                ...state
            }
            case CHECK_OTP_FAILURE:
                state.isLoading = false;
                state.data=payload;
                return {
                    ...state
                }
        default:
            return state;
    }
}

export const forgotPasswordUpdateWeb = (state = passwordUpdated, { type, payload }) => {
    // console.log("inside reducers password",type, payload)
    switch (type){
        case CHECK_PASSWORD_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case CHECK_PASSWORD_SUCCESS:
            state.isLoading = false;
            state.data=payload;
            return {
                ...state
            }
            case CHECK_PASSWORD_FAILURE:
                state.isLoading = false;
                state.data=payload;
                return {
                    ...state
                }
        default:
            return state;
    }
}


export const signUpWeb = (state = signUpUpdated, { type, payload }) => {
    // console.log("inside reducers signUp",type, payload)
    switch (type){
        case CHECK_SIGNUP_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case CHECK_SIGNUP_SUCCESS:
            state.isLoading = false;
            state.data=payload;
            return {
                ...state
            }
            case CHECK_SIGNUP_FAILURE:
                state.isLoading = false;
                state.data=payload;
                return {
                    ...state
                }
        default:
            return state;
    }
}