import {
    FETCH_ORDERS_SUCCESS,
    FETCH_ORDER_DETAIL_SUCCESS,
    FETCH_PRODUCTS_IN_ORDER_SUCCESS,
    FETCH_ORDER_TAX_BREAKUP_SUCCESS,
    FETCH_PRODUCT_TAX_BREAKUP_SUCCESS,
    FETCH_VOID_ORDERS_SUCCESS,
    EMPTY_ORDERS_STATE,
    FETCH_ORDER_WISE_PAYMENT_BREAKUP_SUCCESS,
    FETCH_DOC_SUCCESS,
    FETCH_ODR_SUCCESS,
} from '../actions/types';

export default {
    ordersList : function(state = null, action) {
        switch (action.type) {
            case FETCH_ORDERS_SUCCESS:
                // console.log("kjdfkdjfkjdkfjd",action.payload.data)
                return action.payload.data;
            case EMPTY_ORDERS_STATE:
                return [];
            default:
                return state;
        }
    },

    ordersDetails : function(state = null, action) {
        switch (action.type) {
            case FETCH_ORDER_DETAIL_SUCCESS:
                return action.payload;
            default:
                return state;
        }
    },

    productsInOrder : function(state = null, action) {
        switch (action.type) {
            case FETCH_PRODUCTS_IN_ORDER_SUCCESS:
                // console.log("asdfasdf",action.payload)
                return action.payload;
            default:
                return state;
        }
    },

    orderTaxBreakup : function(state = null, action) {
        switch (action.type) {
            case FETCH_ORDER_TAX_BREAKUP_SUCCESS:
                return action.payload;
            default:
                return state;
        }
    },

    productTaxBreakup : function(state = null, action) {
        switch (action.type) {
            case FETCH_PRODUCT_TAX_BREAKUP_SUCCESS:
                return action.payload;
            default:
                return state;
        }
    },

    voidOrders : function(state = null, action) {
        switch (action.type) {
            case FETCH_VOID_ORDERS_SUCCESS:
                return action.payload.data;
            default:
                return state;
        }
    },

    orderWisePaymentsBreakup : function(state = null, action) {
        switch (action.type) {
            case FETCH_ORDER_WISE_PAYMENT_BREAKUP_SUCCESS:
                return action.payload;
            default:
                return state;
        }
    },

    DOC : function(state = null, action) {
        switch (action.type) {
            case FETCH_DOC_SUCCESS:
                return action.payload.data;
            default:
                return state;
        }
    },

};