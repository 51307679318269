import {GET_PROD_BATCH_VARIANTS,GET_PRODUCT_CATALOGUE, DELETE_ALL_ENTITY} from '../actions/types'

const prodBatchVariantsList=[{

}]

const prodCatalogueList=[{

}]

const getDeleteAllItems = {
    data:[],
    isLoading:null
}

export const getProductsBatchVariantsWeb = (state = prodBatchVariantsList, {type, payload}) => {
    // console.log(payload,'SER---vices');
    
    switch (type) {
        case GET_PROD_BATCH_VARIANTS:
            state.prodBatchVariantsList=payload;
            return {
                ...state
            }
        default:
            return state;
    }
}

export const getCatalogueListWeb = (state = prodCatalogueList, {type, payload}) => {
    // console.log(payload,'pprodCatalogueList');
    
    switch (type) {
        case GET_PRODUCT_CATALOGUE:
            state.prodCatalogueList=payload;
            return {
                ...state
            }
        default:
            return state;
    }
}

export const getDeleteAllItem= (state = getDeleteAllItems, { type, payload }) => {
    switch (type){
            case DELETE_ALL_ENTITY:
            state.isLoading = false;
            state.data=payload;
            return {
                ...state
            }
        default:
            return state;
    }
}