import { SET_HISTORY } from "../actions/types";

export default (state={},action) => {
    const {type,payload={}} = action;
    switch(type){
        case SET_HISTORY:{
            return {...state,...payload};
        }
        default :{
            return state;
        }
    }
}