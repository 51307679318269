import { ALL_CHARGES_REPORTS_FAILURE, ALL_CHARGES_REPORTS_REQUEST, ALL_CHARGES_REPORTS_SUCCESS, CUMULATIVE_COLLECTION_REPORTS_FAILURE, CUMULATIVE_COLLECTION_REPORTS_REQUEST, CUMULATIVE_COLLECTION_REPORTS_SUCCESS, GET_WEB_MUNCIPAL_DETAILS_FAILURE, GET_WEB_MUNCIPAL_DETAILS_REQUEST, GET_WEB_MUNCIPAL_DETAILS_SUCCESS, HALT_LOADER, INIT_LOADER } from "../actions/types";



const getAllChargesData = {
    data: [],
    isLoading: null,
};

const getCumulativeCollectionData = {
   data: [],
   isLoading: null,
};

const getWebMuncipleOerderDetails ={
   data: [],
   attributes:[],
   orderDetail:[],
   orderPayment:[],
   orderSummary:[],
   isLoading: null,
}

export const getAllChargesReportsWeb = (state = getAllChargesData, { type, payload }) => {
    switch (type) {
       case ALL_CHARGES_REPORTS_REQUEST:
          state.isLoading = true;
          return {
             ...state,
          };
       case ALL_CHARGES_REPORTS_SUCCESS:
          state.isLoading = false;
          state.data = payload;
          return {
             ...state,
          };
       case ALL_CHARGES_REPORTS_FAILURE:
          state.isLoading = false;
          return {
             ...state,
          };
       default:
          return state;
    }
};

export const getCumulativeCollectionDataWeb = (state = getCumulativeCollectionData, { type, payload = [] }) => {
   
   switch (type) {
      case CUMULATIVE_COLLECTION_REPORTS_REQUEST:
         state.isLoading = true;
         return {
            ...state,
         };
      case CUMULATIVE_COLLECTION_REPORTS_SUCCESS:
         // console.log(payload,'payloadredcer')
         state.isLoading = false;
         state.data = payload;
         return {
            ...state,
         };
      case CUMULATIVE_COLLECTION_REPORTS_FAILURE:
         state.isLoading = false;
         return {
            ...state,
         };
      default:
         return state;
   }
};

export const getWebMuncipleOerderDetailsWeb = (state = getWebMuncipleOerderDetails, { type, payload = [] }) => {
   
   switch (type) {
      case GET_WEB_MUNCIPAL_DETAILS_REQUEST:
         state.isLoading = true;
         return {
            ...state,
         };
      case GET_WEB_MUNCIPAL_DETAILS_SUCCESS:
         state.isLoading = false;
         state.data = payload;
         state.attributes = payload.attributes;
         state.orderDetail= payload.orderDetail;
         state.orderPayment = payload.orderPayment
         state.orderSummary=payload.orderSummary
         return {
            ...state,
         };
      case GET_WEB_MUNCIPAL_DETAILS_FAILURE:
         state.isLoading = false;
         return {
            ...state,
         };
      default:
         return state;
   }
};

