import { ORDER_DETAIL } from '../actions/types';

const initialState =  [{
            orderSummary:{},
            orderDetail:[{}],
            orderPayment:[{}],
}]


export default (state = initialState, { type, payload }) => {
    switch (type){
        case ORDER_DETAIL:
        // console.log(payload);
        state = payload;
        
        return payload;
        default:
            return state;
    }
}