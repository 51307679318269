import {GET_SIZES,GET_COLORS} from '../actions/types'

const sizesListData=[{

}]

const colorsListData=[{

}]

export const sizes = (state = sizesListData, {type, payload}) => {
    // console.log(payload,'TABLE__DATA');
    
    switch (type) {
        case GET_SIZES:
            state.sizesListData=payload;
            return {
                ...state
            }
        default:
            return state;
    }
}

export const colors = (state = colorsListData, {type, payload}) => {
    // console.log(payload,'TABLE__DATA');
    
    switch (type) {
        case GET_COLORS:
            state.colorsListData=payload;
            return {
                ...state
            }
        default:
            return state;
    }
}