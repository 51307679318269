import { SUCCESS_NOTIFIER,ERROR_NOTIFIER,HALT_NOTIFIER} from '../actions/types';

const notifierStatus = {emitNotifier:false,notifierMessage:""}

export const initNotifier= (state = notifierStatus, { type, payload }) => {

   switch (type){
       case SUCCESS_NOTIFIER:
        //    state.emitNotifier = true;
        //    state.notifierMessage = payload;
        //    state.notifierType = "SUCCESS";
           return {
               ...state,
               emitNotifier: true,
               notifierType: "SUCCESS",
               notifierMessage: payload
           }
           case ERROR_NOTIFIER:
           state.emitNotifier = true;
           state.notifierMessage = payload;
           state.notifierType = "ERROR";
           return {
               ...state
           }
           case HALT_NOTIFIER:
            state.emitNotifier = false;
            state.notifierMessage = "";
            return {
                emitNotifier: false,
                notifierMessage: "",
            }  
       default:
           return state;
   }
}