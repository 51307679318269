import {
    FETCH_PURCHASE_ORDERS_SUCCESS, 
    FETCH_VENDORS_SUCCESS, 
    FETCH_STORE_LIST_SUCCESS, 
    FETCH_WARE_LIST_SUCCESS,
    FETCH_PRODUCTS_SUCCESS,
    FETCH_UNITS_SUCCESS,
    FETCH_TAXES_SUCCESS,
    SELECT_DATE_RANGE,
    SET_MODAL_SHOW,
    ADD_TO_CONFIRM_PRODUCTS,
    REMOVE_FROM_CONFIRM_PRODUCTS,
    ADD_BULK_CONFIRM_PRODUCTS,
    FETCH_PURCHASE_ORDER_DETAIL_SUCCESS,
    FETCH_PI_LIST_SUCCESS,
    LOGOUT,
    CREATE_PURCHASE_ORDER_REQUEST,
    CREATE_PURCHASE_ORDER_SUCCESS,
    CREATE_PURCHASE_ORDER_FAILURE,
} from '../actions/types'

const purchaseOrderReq = {
    data:[],
    isLoading:null
}

  export const purchaseOrdersList = (state = null, action) => {
        switch(action.type) {
            case FETCH_PURCHASE_ORDERS_SUCCESS:
                return action.payload.data.data;
            default:
                return state;
        }
    }
    
    export const poVendorsList = (state = null, action) => {
        switch(action.type) {
            case FETCH_VENDORS_SUCCESS:
                return action.payload.data.data;
            default:
                return state;
        }
    }
    
    export const poStoreList = (state = null, action) => {
        switch(action.type) {
            case FETCH_STORE_LIST_SUCCESS:
                return action.payload.data;
            case LOGOUT:
                return null;
            default:
                return state;
        }
    }
    
    export const poWareList = (state = null, action) => {
        switch(action.type) {
            case FETCH_WARE_LIST_SUCCESS:
                return action.payload.data;
            default:
                return state;
        }
    }
    
    export const products = (state = null, action) => {
        switch(action.type) {
            case FETCH_PRODUCTS_SUCCESS:
                return action.payload.data.list;
            default:
                return state;
        }
    }
    
    export const poUnits = (state = null, action) => {
        switch(action.type) {
            case FETCH_UNITS_SUCCESS:
                return action.payload.data.data;
            default:
                return state;
        }
    }
    
    export const poTaxes = (state = null, action) => {
        switch(action.type) {
            case FETCH_TAXES_SUCCESS:
                return action.payload.data.data;
            default:
                return state;
        }
    }
    
    export const selectedDateRange = (state = null, action) => {
        switch(action.type) {
            case SELECT_DATE_RANGE:
                return action.payload;
            default:
                return state;
        }
    }
    
    export const confirmedProducts = (state = [], action) => {
        switch(action.type) {
            case ADD_TO_CONFIRM_PRODUCTS:
                let newState = JSON.parse(JSON.stringify(state));
                newState.push(action.payload);
                return newState;
            case REMOVE_FROM_CONFIRM_PRODUCTS:
                let newState1 = JSON.parse(JSON.stringify(state));
                let i = 0;
                while(i < newState1.length) {
                    if (newState1[i].productID === action.payload) break;
                    i++;
                }
                newState1.splice(i, 1);
                return newState1;
            case ADD_BULK_CONFIRM_PRODUCTS:
                return action.payload;
            default:
                return state;
        }
    }
    
    export const modalShow = (state = null, action) => {
        switch(action.type) {
            case SET_MODAL_SHOW:
                return action.payload;
            default:
                return state;
        }
    }
    
    export const PODetail = (state = null, action) =>  {
        switch (action.type) {
            case FETCH_PURCHASE_ORDER_DETAIL_SUCCESS:
                return action.payload.data;
            default:
                return state;
        }
    }

    // export const createPurchaseOrder = (state = purchaseOrderReq, { type, payload }) => {
    //     switch (type){
    //         case CREATE_PURCHASE_ORDER_REQUEST:
    //             state.isLoading = true;
    //             return {
    //                 ...state
    //             }
    //             case CREATE_PURCHASE_ORDER_SUCCESS:
    //             state.isLoading = false;
    //             state.data=payload;
    //             return {
    //                 ...state
    //             }
    //             case CREATE_PURCHASE_ORDER_FAILURE:
    //                 state.isLoading = false;
    //                 return {
    //                     ...state
    //                 }
    //         default:
    //             return state;
    //     }
    // }