import {
    FETCH_CUSTOMER_CREDIT_SALE_SUCCESS,
    FETCH_CREDIT_SETTLEMENT_SUCCESS,
    FETCH_CN_HISTORY_SUCCESS,
    FETCH_MESSAGE_HISTORY_SUCCESS,
    FETCH_INACTIVE_CUSTOMERS_SUCCESS,
    FETCH_CUSTOMERS_BALANCE_SUCCESS,
    FETCH_CUSTOMER_LEDGER_SUCCESS,
    FETCH_CUSTOMER_DASHBOARD_SUCCESS,
    FETCH_POINT_LEDGER,
    FETCH_REPORT_CUSTOMER_SALES
} from '../actions/types';

let reducers = [
    {name: 'messageHistory', actionType: FETCH_MESSAGE_HISTORY_SUCCESS},
    {name: 'inactiveCustomers', actionType: FETCH_INACTIVE_CUSTOMERS_SUCCESS},
    {name: 'customersBalance', actionType: FETCH_CUSTOMERS_BALANCE_SUCCESS},
    {name: 'customerLedger', actionType: FETCH_CUSTOMER_LEDGER_SUCCESS},
    {name: 'customerDashboard', actionType: FETCH_CUSTOMER_DASHBOARD_SUCCESS},
    {name:'pointLedger', actionType:FETCH_POINT_LEDGER},
    {name:'salesOfCustomers',actionType:FETCH_REPORT_CUSTOMER_SALES}
]


let commonReducer = (i) => function(state = null, action) {
    switch(action.type) {
        case reducers[i].actionType:
            return action.payload;
        default:
            return state;
    }
}

let customersReducer = {
    customerCreditSale: function(state = null, action) {
        switch(action.type) {
            case FETCH_CUSTOMER_CREDIT_SALE_SUCCESS:
                return action.payload;
            default:
                return state;
        }
    },

    creditSettlement: function(state = null, action) {
        switch(action.type) {
            case FETCH_CREDIT_SETTLEMENT_SUCCESS:
                return action.payload;
            default:
                return state;
        }
    },

    CNHistory: function(state = null, action) {
        switch(action.type) {
            case FETCH_CN_HISTORY_SUCCESS:
                return action.payload;
            default:
                return state;
        }
    },
    ledgerPoint: function(state= null, action){
        switch(action.type) {
            case FETCH_POINT_LEDGER:
                return action.payload;
            default:
                return state;
        }
    },
    salesOfCustomer: function(state= null, action){
        switch(action.type) {
            case FETCH_REPORT_CUSTOMER_SALES:
                return action.payload;
            default:
                return state;
        }
    }
   
};

customersReducer[reducers[0].name] = commonReducer(0);
customersReducer[reducers[1].name] = commonReducer(1);
customersReducer[reducers[2].name] = commonReducer(2);
customersReducer[reducers[3].name] = commonReducer(3);
customersReducer[reducers[4].name] = commonReducer(4);
customersReducer[reducers[5].name] = commonReducer(5);
customersReducer[reducers[6].name] = commonReducer(6);

export default customersReducer;