import { TAX_LIST,GET_TAX_PRODS,GET_CHARGE_PRODS } from "../actions/types";

const initialState = [];

const getTaxProdsStatus = {
    data:[],
    isLoading:null
}

const getChargeProdsStatus = {
    data:[],
    isLoading:null
}

export default (state = initialState,action) => {


    switch(action.type){

        case TAX_LIST: 

        state = action.payload;
        return state;
    }
    return state;
}

export const getTaxProds = (state = getTaxProdsStatus, {type, payload}) => {
    switch (type) {
        case GET_TAX_PRODS:
            state.data=payload;
            return {
                ...state
            }
        default:
            return state;
    }
}

export const chargeProds = (state = getChargeProdsStatus, {type, payload}) => {
    switch (type) {
        case GET_CHARGE_PRODS:
            state.data=payload;
            return {
                ...state
            }
        default:
            return state;
    }
}