import {  FETCH_UP_CHAIN_SETTINGS_FAILURE, FETCH_UP_CHAIN_SETTINGS_REQUEST, FETCH_UP_CHAIN_SETTINGS_SUCCESS,FETCH_URBANPIPER_STOREPRODS_REQUEST,FETCH_URBANPIPER_STOREPRODS_SUCCESS,FETCH_URBANPIPER_STOREPRODS_FAILURE, UPBANPIPER_API_LOGS_REQUEST, UPBANPIPER_API_LOGS_SUCCESS, UPBANPIPER_API_LOGS_FAILURE, FETCH_UP_STORE_SETTINGS_FAILURE, FETCH_UP_STORE_SETTINGS_REQUEST, FETCH_UP_STORE_SETTINGS_SUCCESS } from "../actions/types";

const upChainStoreData= {
    data:[],
    isLoading:false
}

const upStoreSettingsData= {
    data:[],
    isLoading:false
}

const upProdsDataStatus= {
    data:[],
    isLoading:false
}

const urbanPiperApiLogsData = {
    data:[],
    isLoading: false
}

export const urbanChainStoreSettings= (state = upChainStoreData, { type, payload }) => {
    
    switch (type){
        case FETCH_UP_CHAIN_SETTINGS_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case FETCH_UP_CHAIN_SETTINGS_SUCCESS:
            state.isLoading = false;
            state.data=payload
            return {
                ...state
            }
            case FETCH_UP_CHAIN_SETTINGS_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}

export const urbanPiperApiLogs= (state = urbanPiperApiLogsData, { type, payload }) => {
    
    switch (type){
        case UPBANPIPER_API_LOGS_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
        case UPBANPIPER_API_LOGS_SUCCESS:
            state.isLoading = false;
            state.data=payload
            return {
                ...state
            }
        case UPBANPIPER_API_LOGS_FAILURE:
            state.isLoading = false;
            return {
                ...state
            }
        default:
            return state;
    }
}

export const urbanPiperStoreProds= (state = upProdsDataStatus, { type, payload }) => {
    switch (type){
        case FETCH_URBANPIPER_STOREPRODS_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case FETCH_URBANPIPER_STOREPRODS_SUCCESS:
            state.isLoading = false;
            state.data=payload
            return {
                ...state
            }
            case FETCH_URBANPIPER_STOREPRODS_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}

export const urbanStoreSettings= (state = upStoreSettingsData, { type, payload }) => {
    
    switch (type){
        case FETCH_UP_STORE_SETTINGS_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case FETCH_UP_STORE_SETTINGS_SUCCESS:
            state.isLoading = false;
            state.data=payload
            return {
                ...state
            }
            case FETCH_UP_STORE_SETTINGS_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}