import {DISCOUNTS_LIST,PROD_VARIANT_LIST} from '../actions/types'

const addDiscountStatus =  {
    isLoading:null
}

const editDiscountStatus = {
    isLoading:null
}

const discountListData=[]

const prodVariantListData=[]

export const discounts = (state = discountListData, {type, payload}) => {
    
    switch (type) {
        case DISCOUNTS_LIST:
            state=payload;
            return state;
        default:
            return state;
    }
}

export const prodVariants = (state = prodVariantListData, {type, payload}) => {
    
    switch (type) {
        case PROD_VARIANT_LIST:
            state=payload;
            return state
        default:
            return state;
    }
}