import React from "react";
const QueuebuserPage = () => {
    const brandConfig = require(`../../assets/whiteLabel_brands/${process.env.REACT_APP_BRAND}`);

    const width = window.screen.width < 450 ? "w-50" : "w-auto";
    return (
        <div className="d-flex justify-content-center align-items-center" style={{height:"100vh"}}>
            <img className={width} style={{borderRadius:"2.7rem"}} src={brandConfig.default.suspenseLoaderUrl} alt="loader"/>
        </div>
    );
}
export default QueuebuserPage;